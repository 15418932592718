import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import AccordionToggleHeader from "./AccordionToggleHeader";

import OpenIcon from '@icons/plus.inline.svg';
import CloseIcon from '@icons/minus.inline.svg';

function createHTMLMarkup(props) {
    return { __html: (props.html) };
}

function HtmlText(props) {
    return <div dangerouslySetInnerHTML={createHTMLMarkup(props)} />;
}
const TextAccordion = (props) => {
    const [activeKey, setActiveKey] = useState(false);
    return (
        <div class="text-accordion-wrapper">
            <h2>{props?.accordionTitle }</h2>
            <Accordion>
                {props.accordionData?.map((item, index) => ( 
                <Card>
                    <AccordionToggleHeader
                                as={Card.Header}
                                eventKey={item.id}
                                handleClick={() => {
                                    (activeKey === item.id) ? setActiveKey(null) : setActiveKey(item.id);
                                }}
                    >
                        
                            <span className='heading'>{item.Title}</span>
                            {activeKey === item.id ? <CloseIcon /> : <OpenIcon />}
                    </AccordionToggleHeader>
                    <Accordion.Collapse eventKey={item.id}>
                       <Card.Body><HtmlText html={item.Content} /></Card.Body>
                    </Accordion.Collapse>
                </Card>
                ))}
            </Accordion>
            
        </div>
    );
}
export default TextAccordion;