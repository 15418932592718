import React, { useState, useRef, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types';
import { Container, Row, Col } from "react-bootstrap";

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import  "./assets/_index.scss";

import ContactForm from "@components/forms/contact/contact-form";
import ContactSidebar from "@components/modules/contact-sidebar/";

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const Contact = (props) => {

    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }

        setValidated(true);
    };

    let formAnim = useRef(null);
    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: formAnim.duration,
                ease: animSetting.ease,
            },
            scrollTrigger: {
                trigger: formAnim,
                start: 'top 85%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: formAnim.ease
            }
        });
        tl.fromTo(formAnim, {
            opacity: 0,
            y: 70,
        },{
            opacity: 1,
            y: 0,
            duration: 1.5
        })
    }, [])

    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "young-woman.jpg" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 400) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
    const sidebarData = {
        hideModuleBg: true,
        sidebarVideo: {
            posterImage: data.file,
            videoask: true,
            videoId:"https://www.videoask.com/fkhi9ib3m",
            videoTitle: "Contact us for estate agency recruitment and more great job opportunities within the property industry."
        }
    }

    return (
        <section className={`section section-contact${props.sectionClass}`}>
            <Container>
                <Row>
                    <Col xs={12}>
                        {props.showTitle && <h2 className={`section-title${props.titleClass}`}>{props.title}</h2>}
                        <Row id="sidebar-end-scroll">
                            <Col xs={12} xl={8} ref={el => formAnim = el}>
                                <p className={props.introClass}>{props.intro}</p>
                                <ContactForm
                                    formClass={props.formClass}
                                    formSubmitContainerClass={props.formSubmitContainerClass}
                                    formSubmitClass={props.formSubmitClass}
                                    formTermsClass={props.formTermsClass}
                                    formRequiredClass={props.formRequiredClass}
                                />
                            </Col>
                            <ContactSidebar
                                sidebarData={sidebarData}
                            />
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

// Check all the prop types
Contact.propTypes = {
    sectionClass: PropTypes.string,
    titleClass: PropTypes.string,
    showTitle: PropTypes.bool,
    title: PropTypes.string,
    introClass: PropTypes.string,
    intro: PropTypes.string,
    formClass: PropTypes.string,
    formSubmitContainerClass: PropTypes.string,
    formSubmitClass: PropTypes.string,
    formTermsClass: PropTypes.string,
    formRequiredClass: PropTypes.string
};

// Specifies the default values for props:
Contact.defaultProps = {
    sectionClass: ' pt-34 pb-36 pt-md-52 pb-md-60 pt-xl-72 pb-xl-94',
    titleClass: ' col-xs-12 mb-14 mb-md-27 mb-xl-31',
    showTitle: true,
    title: 'Applying for Business Development Executive',
    introClass: 'mb-23 mb-md-48',
    intro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras mattis dictum purus, et condimentum arcu dapibus in lipsum dolor.',
    formClass: '',
    formSubmitContainerClass: 'mt-16',
    formSubmitClass: 'btn-block',
    formTermsClass: 'form-dark__terms mt-32',
    formRequiredClass: 'form-dark__required font-weight-bold mt-24 mt-md-32 mt-xl-24',
    sidebarData: [
        {
            sidebarVideo: {
                videoask: true,
            },
            hideModuleBg: true,
        }
    ]
};

export default Contact