import React, { useRef, useEffect } from "react";
import { Link, navigate } from "gatsby"
import { Container, Row, Col, Button, Form, InputGroup, FormControl } from "react-bootstrap";
// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import "./assets/scss/_index.scss";

import SearchIcon from '@icons/search.inline.svg';
import LocationIcon from '@icons/marker.inline.svg';
import FormBtnIcon from '@icons/form-btn.inline.svg';

import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    connectRange,
    RefinementList
} from 'react-instantsearch-dom';

const searchClient = algoliasearch(
    `${process.env.GATSBY_ALGOLIA_APP_ID}`,
    `${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
);

const priceData = [
    {
        label: "Any Salary",
        min: "0",
        max: ""
    },
    {
        label: "£10,000 - £14,999",
        min: "10000",
        max: "14999"
    },
    {
        label: "£15,000 - £24,999",
        min: "15000",
        max: "24999"
    },
    {
        label: "£25,000 - £49,999",
        min: "25000",
        max: "49999"
    },
    {
        label: "£50,000 - £79,999",
        min: "50000",
        max: "79999"
    },
    {
        label: "£80,000 +",
        min: "80000",
        max: ""
    }
]

const SalaryBox = ({ currentRefinement, min, max, refine }) => {
    let priceFilter = priceData.filter(x => x.min < max); // filter out the values with specified max > then available max
    let len = priceData.length;
    // adjust the last object in array to convert it to from [price] + value
    const priceFilterAdj = priceData.map((p, i) => {
        if (len === i + 1) { // last object in the array
            //let labelNew = '£' + max.toLocaleString() + ' +';
            //let minNew = max;
            //let maxNew = undefined;
            return { ...p };
        }
        return p;
    });

    let priceRange = priceFilterAdj;

    let priceValSelect = "";
    // adding both min,max values with comma separation so we can access both of them when filtering
    if (currentRefinement.min !== undefined) {
        priceValSelect = `${currentRefinement.min},${currentRefinement.max}`
    }
    if (currentRefinement.min === currentRefinement.max) {
        priceValSelect = `${currentRefinement.min},`
    }
    if (currentRefinement.max === undefined) {
        priceValSelect = `${currentRefinement.min},`
    }
    return (
        <>
            {/* <Form.Group controlId="salary-select"> */}
            <Form.Label srOnly>Select Salary</Form.Label>
            <Form.Control
                as="select"
                name="salary"
                custom
                value={priceValSelect}
                onChange={event => {
                    let targetVal = event.currentTarget.value;
                    // split the comma separated string to an array to access min and max values
                    let targetValArr = targetVal.split(',');
                    // show all results
                    if (targetValArr[0] === "0" && targetValArr[1] === "") {
                        refine({
                            min: 0,
                            max: max
                        })
                        // show last results with max set to largest max value
                    } else if (targetValArr[1] === "") {
                        refine({
                            min: targetValArr[0],
                            max: max
                        })
                        // normal filtering defined in the array
                    } else {
                        refine({
                            min: targetValArr[0],
                            max: targetValArr[1]
                        })
                    }
                }}
            >
                {priceRange.map(item => (
                    <option
                        key={item.label}
                        value={[item.min, item.max]}
                    >
                        {item.label}
                    </option>
                ))}
            </Form.Control>
            {/* </Form.Group> */}
        </>
    )
}
const CustomSalaryBox = connectRange(SalaryBox);

const priceDataOte = [
    {
        label: "Any OTE",
        min: "0",
        max: ""
    },
    {
        label: "£10,000 - £14,999",
        min: "10000",
        max: "14999"
    },
    {
        label: "£15,000 - £24,999",
        min: "15000",
        max: "24999"
    },
    {
        label: "£25,000 - £49,999",
        min: "25000",
        max: "49999"
    },
    {
        label: "£50,000 - £79,999",
        min: "50000",
        max: "79999"
    },
    {
        label: "£80,000 +",
        min: "80000",
        max: ""
    }
]
const OteBox = ({ currentRefinement, min, max, refine }) => {
    let priceFilter = priceDataOte.filter(x => x.min < max); // filter out the values with specified max > then available max
    let len = priceDataOte.length;
    // adjust the last object in array to convert it to from [price] + value
    const priceFilterAdj = priceDataOte.map((p, i) => {
        if (len === i + 1) { // last object in the array
            //let labelNew = '£' + max.toLocaleString() + ' +';
            //let minNew = max;
            //let maxNew = undefined;
            return { ...p };
        }
        return p;
    });

    let priceRange = priceFilterAdj;

    let priceValSelect = "";
    // adding both min,max values with comma separation so we can access both of them when filtering
    if (currentRefinement.min !== undefined) {
        priceValSelect = `${currentRefinement.min},${currentRefinement.max}`
    }
    if (currentRefinement.min === currentRefinement.max) {
        priceValSelect = `${currentRefinement.min},`
    }
    if (currentRefinement.max === undefined) {
        priceValSelect = `${currentRefinement.min},`
    }
    return (
        <>
            {/* <Form.Group controlId="salary-select"> */}
            <Form.Label srOnly>Select OTE</Form.Label>
            <Form.Control
                as="select"
                name="ote"
                custom
                value={priceValSelect}
                onChange={event => {
                    let targetVal = event.currentTarget.value;
                    // split the comma separated string to an array to access min and max values
                    let targetValArr = targetVal.split(',');
                    // show all results
                    if (targetValArr[0] === "0" && targetValArr[1] === "") {
                        refine({
                            min: 0,
                            max: max
                        })
                        // show last results with max set to largest max value
                    } else if (targetValArr[1] === "") {
                        refine({
                            min: targetValArr[0],
                            max: max
                        })
                        // normal filtering defined in the array
                    } else {
                        refine({
                            min: targetValArr[0],
                            max: targetValArr[1]
                        })
                    }
                }}
            >
                {priceRange.map(item => (
                    <option
                        key={item.label}
                        value={[item.min, item.max]}
                    >
                        {item.label}
                    </option>
                ))}
            </Form.Control>
            {/* </Form.Group> */}
        </>
    )
}
const CustomOteBox = connectRange(OteBox);

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const FindJob = (props) => {
    let contAnim = useRef(null);
    let titleAnim = useRef(null);
    let form = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            },
            scrollTrigger: {
                trigger: contAnim,
                start: 'top 80%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        });
        tl.to(contAnim, {
            duration: 0,
            css: { visibility: 'visible' }
        })
            .from([titleAnim, form], {
                opacity: 0,
                y: 70,
                duration: 1.5,
                stagger: 0.6
            })
    }, [])

    const handleSubmit = (event) => {

        event.preventDefault();
        const formsdata = (event.target);
        const json = {}
        Object.keys(formsdata).map(key => (
            json[formsdata[key].name] = formsdata[key].value
        ))

        let url = "/job-search";
        let searchTerm = "";
        let salary = "";
        let ote = "";

        if (json['algolia_search'] !== "") {
            searchTerm = json['algolia_search'].replace(' ', '+');
            url = url + "/search-" + searchTerm;
        }
        if (json['salary'] !== "") {
            salary = json['salary'];
            // split the comma separated string to an array to access min and max values
            let salaryValArr = salary.split(',');
            // show all results
            if (salaryValArr[0] === "0" && salaryValArr[1] === "") {
                url = url;
                // show last results with min set to largest max value
            } else if (salaryValArr[1] === "") {
                url = url + "/salary-over-" + salaryValArr[0];
                // normal filtering defined in the array
            } else {
                url = url + "/salary-between-" + salaryValArr[0] + "-and-" + salaryValArr[1];
            }

        }
        if (json['ote'] !== "") {
            ote = json['ote'];
            // split the comma separated string to an array to access min and max values
            let oteValArr = ote.split(',');
            // show all results
            if (oteValArr[0] === "0" && oteValArr[1] === "") {
                url = url;
                // show last results with min set to largest max value
            } else if (oteValArr[1] === "") {
                url = url + "/ote-over-" + oteValArr[0];
                // normal filtering defined in the array
            } else {
                url = url + "/ote-between-" + oteValArr[0] + "-and-" + oteValArr[1];
            }

        }
        if (!url.endsWith('/'))
            url = url + '/';
        navigate(url);
    }

    return (
        <section className="section section-primary section-find-job-role pt-33 pb-35 pt-sm-44 pb-sm-48" ref={el => contAnim = el}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <h2 className="section-title h4 col-xs-12 mb-11 mb-sm-23" ref={el => titleAnim = el}>{props.Title}</h2>
                        <Form className="form-find-job-role col-xs-12" ref={el => form = el} onSubmit={handleSubmit}>
                            <Form.Row className="align-items-center">
                                {/* <Col xs={12} sm={8} xl={5} className="mb-12 mb-sm-21">
                                    <Form.Label htmlFor="inlineFormInputKeyword" srOnly>
                                        Keyword, e.g. Sales
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>
                                                <SearchIcon className="icon-search" />
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control id="inlineFormInputKeyword" placeholder="Keyword, e.g. Sales" name="algolia_search" />
                                        <InputGroup.Append className="d-sm-none mobile-submit-btn">
                                            <Button type="submit"><FormBtnIcon /></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col> */}
                                {/* <Col xl={5} className="d-none d-xl-block mb-12 mb-sm-21">
                                    <InstantSearch
                                        indexName="jobs"
                                        searchClient={searchClient}
                                        // searchState={searchState}
                                        // onSearchStateChange={onSearchStateChange}
                                        // createURL={createURL}
                                        // routing="true"
                                    >
                                        <Form.Row>
                                            <Col xl={6}>
                                                <RefinementList
                                                    attribute="status"
                                                    defaultRefinement={['true']}
                                                />
                                                <CustomSalaryBox attribute="salary" min={0} />
                                            </Col>
                                            <Col xl={6}>
                                                <CustomOteBox attribute="ote" min={0} />
                                            </Col>
                                        </Form.Row>
                                    </InstantSearch>
                                </Col> */}
                                {/* <Col xs="auto" sm={4} md={2} className="d-none d-sm-block mb-12 mb-sm-21">
                                    <Button type="submit">Submit</Button>
                                </Col>
                                <Col xs={12} className="form-find-job-role__link">
                                    Let employers find you: <Link to="/candidates/upload-your-cv/">Upload Your CV</Link>
                                </Col> */}
                            </Form.Row>
                        </Form>
                    </Col>
                    <Col xs={12} className="find-job-btn-blk">
                        {props.moduleType[0]?.Btn_CTA_1_Url != null && props.moduleType[0]?.Btn_CTA_1_Label != null &&
                            <a className="btn btn-primary section-image-text__text__btn" href={`${props.moduleType[0]?.Btn_CTA_1_Url}/`}>{props.moduleType[0]?.Btn_CTA_1_Label}</a>
                        }
                        {props.moduleType[0]?.Btn_CTA_2_Url != null && props.moduleType[0]?.Btn_CTA_2_Label != null &&
                            <a className="btn btn-primary section-image-text__text__btn" href={`${props.moduleType[0]?.Btn_CTA_2_Url}/`}>{props.moduleType[0]?.Btn_CTA_2_Label}</a>
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default FindJob

// Specifies the default values for props:
FindJob.defaultProps = {
    Title: 'Find your next job role'
}