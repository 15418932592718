import React from "react"
import "./assets/styles/_index.scss"
import ReactReadMoreReadLess from "react-read-more-read-less"
import Col from "react-bootstrap/Col"

import StarIcon from './assets/img/star-icon.inline.svg';

const SingleReview = props => {
  const { starRating, comment, reviewer, innerRef } = props;
  const star = <li className="list-inline-item"><StarIcon /></li>;
  return (
    <>
        {comment !== '' && comment !== null &&
            <Col xs={12} lg={6} xl={4} className={`testimonial-card ${props.boxClass}`} ref={innerRef}>
                <div className="testimonial-wrapper text-center">
                    <ul className="list-inline star-rating">
                    {starRating === "ONE" &&
                        <>
                        {star}
                        </>
                    }
                    {starRating === "TWO" &&
                        <>
                        {star}
                        {star}
                        </>
                    }
                    {starRating === "THREE" && (
                        <>
                        {star}
                        {star}
                        {star}
                        </>
                    )}
                    {starRating === "FOUR" && (
                        <>
                        {star}
                        {star}
                        {star}
                        {star}
                        </>
                    )}
                    {starRating === "FIVE" && (
                        <>
                        {star}
                        {star}
                        {star}
                        {star}
                        {star}
                        </>
                    )}
                    </ul>

                    {comment && (
                        <p className="content-comment mb-13 mb-md-16">
                            <ReactReadMoreReadLess
                                charLimit={120}
                                readMoreText={"more"}
                                readLessText={"less"}
                                readMoreClassName="read-more-less--more"
                                readLessClassName="read-more-less--less"
                                >
                                {comment}
                            </ReactReadMoreReadLess>
                        </p>
                    )}

                    <p className="content_reviewer font-weight-bold">{reviewer}</p>
                </div>
            </Col>
        }
    </>
  )
}

export default SingleReview
