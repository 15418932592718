import React, { useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from 'react-multi-carousel';
// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import 'react-multi-carousel/lib/styles.css';
import  "./assets/_index.scss";

import VideoModal from "@components/modal/video-modal-img";
import BackgroundImage from 'gatsby-background-image';
import Button from 'react-bootstrap/Button'
import ArrowIconRight from '@icons/arrow-right.inline.svg';
import ArrowIconLeft from '@icons/arrow-left.inline.svg';

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const person1 = require("@images/young-woman.jpg");
const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1025 },
      items: 6,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 599, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    }
};

const ButtonGroup = ({ next, previous, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <div className="custom-carousel-arrows">
            <Button className={currentSlide === 0 ? 'disable custom-carousel-arrows-left' : 'custom-carousel-arrows-left'} onClick={() => previous()} variant="link"><ArrowIconLeft /></Button>
            <Button className="custom-carousel-arrows-right" onClick={() => next()} variant="link"><ArrowIconRight /></Button>
        </div>
    );
}

const VideoTeamTestimonials = (props) => {
    const data = useStaticQuery(graphql`
    {
        allStrapiTestimonials(filter: {testimonialCategory: {eq: "Employer"}, Published: {eq: true}}) {
            edges {
                node {
                    id
                    Title
                    Subtitle
                    testimonialCategory
                    sidebarVideo {
                        showVideo
                        videoId
                        videoChannel
                        posterImage {
                            childImageSharp {
                                fluid(quality: 90, maxWidth: 180, maxHeight: 120, cropFocus: CENTER, fit: COVER, background: "#fff") {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    `)

    let contAnim = useRef(null);
    let titleAnim = useRef(null);
    let carousel = useRef();
    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            },
            scrollTrigger: {
                trigger: contAnim,
                start: 'top 80%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        });
        tl.add('start')
        .to(contAnim, {
            duration: 0,
            css: {visibility: 'visible'}
        }, 'start')
        .from(titleAnim, {
            opacity: 0,
            y: 70,
            duration: 1.5
        }, 'start')
        .to(carousel.containerRef.current, {
            opacity: 1,
            y: 0,
        }, 'start+=0.5')
    }, [])

    return (
        <section id="scrollToVideoTest" className={`section section-team-testimonial-carousel${props.sectionClass}`} ref={el => contAnim = el}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <h2 className="section-title h5 font-weight-bold mb-21 mb-md-42" ref={el => titleAnim = el}>{props.Title}</h2>
                        <Carousel
                            additionalTransfrom={0}
                            arrows={false}
                            customButtonGroup={<ButtonGroup />}
                            removeArrowOnDeviceType={["desktop"]}
                            autoPlay
                            autoPlaySpeed={3000}
                            centerMode={false}
                            className="team-testimonial-carousel"
                            containerclassName="container-with-dots"
                            showDots
                            dotListClass="mt-40 stb-dot-list-style"
                            draggable
                            focusOnSelect={false}
                            infinite
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            renderButtonGroupOutside
                            renderDotsOutside={true}
                            partialVisible={true}
                            responsive={responsive}
                            sliderclass=""
                            slidesToSlide={1}
                            swipeable
                            ref={(el) => (carousel = el)}
                        >
                            {data.allStrapiTestimonials.edges.map((slide, i) => 
                                <div className="team-testimonial-carousel__item mx-12 mx-md-20" key={i}>
                                    {slide.node.sidebarVideo.showVideo ? (
                                        <VideoModal
                                            imgUrl={slide.node.sidebarVideo.posterImage ? slide.node.sidebarVideo.posterImage.childImageSharp.fluid : null}
                                            imgClass=" mb-14 mb-md-17"
                                            showVideo={slide.node.sidebarVideo.showVideo}
                                            videoId={slide.node.sidebarVideo.videoId}
                                            videoChannel={slide.node.sidebarVideo.videoChannel}
                                            hideCircleIcon={true}
                                            gtmTag={true}
                                            name={slide.node.Title}
                                        />
                                    ) : (
                                        <BackgroundImage
                                            Tag="div"
                                            className="video-text-module__image mb-14 mb-md-17"
                                            fluid={slide.node.sidebarVideo.posterImage ? slide.node.sidebarVideo.posterImage.childImageSharp.fluid : null}
                                            // preserveStackingContext={true}
                                            critical={true}
                                            role="img"
                                        />
                                    )}
                                    <h5 className="team-testimonial-carousel__item__title font-weight-bold mb-0">{slide.node.Title}</h5>
                                    <span className="team-testimonial-carousel__item__job__title">{slide.node.Subtitle}</span>
                                </div>
                            )}

                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

// Check all the prop types
VideoTeamTestimonials.propTypes = {
    sectionClass: PropTypes.string,
    members: PropTypes.array
};

// Specifies the default values for props:
VideoTeamTestimonials.defaultProps = {
    sectionClass: ' mb-31 mb-md-51 mb-xl-135',
    members: [
        {
            Name: 'James',
            Image: person1,
            JobTitle: 'adadk'
        },
        {
            Name: 'James',
            Image: person1,
            JobTitle: 'adadk'
        },
        {
            Name: 'James',
            Image: person1,
            JobTitle: 'adadk'
        },
        {
            Name: 'James',
            Image: person1,
            JobTitle: 'adadk'
        },
        {
            Name: 'James',
            Image: person1,
            JobTitle: 'adadk'
        },
        {
            Name: 'James',
            Image: person1,
            JobTitle: 'adadk'
        },
        {
            Name: 'James',
            Image: person1,
            JobTitle: 'adadk'
        }
    ]
};

export default VideoTeamTestimonials;