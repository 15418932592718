import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import SingleReview from "./_singleReview";
import ReviewsScore from "./_reviewsScore";

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const LatestGoogleReviews = (props) => {
    let contAnim = useRef(null);
    let titleAnim = useRef(null);
    let boxAnim = useRef([]);
    boxAnim.current = [];

    const addToRefs = el => {
        if (el && !boxAnim.current.includes(el)) {
            boxAnim.current.push(el);
        }
    };

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            },
            scrollTrigger: {
                trigger: contAnim,
                start: 'top 80%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        });
        tl.fromTo(titleAnim, {
            opacity: 0,
            y: 100,
            duration: 0.8
        },{
            opacity: 1,
            y: 0
        }).fromTo(boxAnim.current, {
            opacity: 0,
            y: 70
        },{
            opacity: 1,
            y: 0,
            stagger: 0.3
        })
    }, [])

    const latestReviews = useStaticQuery(graphql`
        query {
            allStrapiGoogleReviewReviews(limit: 4, sort: {fields: createTime, order: DESC}, filter: {starRating: {in: ["FOUR","FIVE"]}}) {
                edges {
                    node {
                        name
                        comment
                        id
                        reviewer {
                        displayName
                        profilePhotoUrl
                        }
                        starRating
                    }
                }
            },
            file(relativePath: { eq: "google-reviews-logo.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 180) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return (
        <section className={`section section-google-reviews section-latest-google-reviews${props.sectionClass}`} ref={el => contAnim = el}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className={props.reviewLogoContainer} ref={el => titleAnim = el}>
                            {props.reviewLogo &&
                                <Img fluid={latestReviews.file.childImageSharp.fluid} className={`section-google-reviews-logo${props.reviewLogoClass}`} alt="google reviews" />
                            }
                            {props.Title &&
                                <div className={props.reviewScoreText}>
                                    <p><ReviewsScore /></p>
                                    <h2 className={props.titleClass}>{props.Title}</h2>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
                <Row className={props.moduleClass}>
                    {latestReviews.allStrapiGoogleReviewReviews.edges.map((item) => (
                        <SingleReview
                            key={item.node.id}
                            boxClass={props.boxClass}
                            starRating={item.node.starRating}
                            comment={item.node.comment}
                            reviewer={item.node.reviewer.displayName}
                            innerRef={addToRefs}
                        />
                    ))}
                </Row>
            </Container>
        </section>
    );
}

// Check all the prop types
LatestGoogleReviews.propTypes = {
    sectionClass: PropTypes.string,
    titleClass: PropTypes.string,
    moduleData: PropTypes.array
};

// Specifies the default values for props:
LatestGoogleReviews.defaultProps = {
    sectionClass: ' section-grey-bg pt-40 pb-20 pt-md-60 pb-md-40 pt-xl-140 pb-xl-100',
    moduleClass: 'd-flex flex-column flex-lg-row latest-google-reviews-container',
    boxClass: ' pb-20 pb-xl-40',
    titleClass: 'h6 mb-0 section-google-reviews-title',
    btnClass: 'btn btn-outline-secondary mt-21 mt-md-31 mt-xl-38',
    reviewLogoContainer: 'mb-30 mb-md-40 mb-lg-60 d-flex flex-column align-items-center flex-md-row justify-content-md-center',
    reviewLogo: true,
    reviewLogoClass: ' mb-10 mb-md-0',
    reviewLogoTitle: '',
    reviewScoreText: ' ml-md-40 text-center text-md-left',
    moduleData: [
        {
            id: 1,
            starRating: "TWO",
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis. Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            reviewer: 'Chris Rowe / Foxtons'
        },
        {
            id: 2,
            starRating: "FIVE",
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            reviewer: 'Andrew Smith / Savills'
        },
        {
            id: 1,
            starRating: "TWO",
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            reviewer: 'Chris Rowe / Foxtons'
        },
        {
            id: 2,
            starRating: "FIVE",
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            reviewer: 'Andrew Smith / Savills'
        },
        {
            id: 1,
            starRating: "TWO",
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            reviewer: 'Chris Rowe / Foxtons'
        },
        {
            id: 2,
            starRating: "FIVE",
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            reviewer: 'Andrew Smith / Savills'
        }
    ]
};

export default LatestGoogleReviews