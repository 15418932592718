
import React from 'react';
import { StaticQuery, graphql } from "gatsby";
import Col from "react-bootstrap/Col";
import CalendlyButtonLink from "@components/book-video-call/";

import OurPeopleCardName from "./_ourPeopleCardName";
import OurPeopleCardJobTitle from "./_ourPeopleCardJobTitle";
import OurPeopleCardPhone from "./_ourPeopleCardPhone";
// import OurPeopleCardVideo from "./_ourPeopleCardVideoCall";
import OurPeopleCardBio from "./_ourPeopleCardBioLink";
import OurPeopleCardImg from "./_ourPeopleCardImg";

const OurPeopleCard = (props) => {
    return (
        <StaticQuery
          query={graphql`
            query {
                allStrapiTeams(filter: {Publish: {eq: true}}) {
                    edges {
                        node {
                            strapiId
                            BookVideoLabel
                            Image {
                                internal {
                                    description
                                }
                                childImageSharp {
                                    fluid(quality: 90, maxWidth: 760, maxHeight: 660, cropFocus: CENTER, fit: COVER, background: "#fff") {
                                        ...GatsbyImageSharpFluid
                                    }
                                    resize(width: 500) {
                                      src
                                    }
                                }
                            }
                            JobTitle
                            Name
                            PhoneNumber
                            ViewBioLabel
                            Slug
                            CalendlyLink
                        }
                    }
                }
            }
          `}
          render={data => (
            <>
                {data.allStrapiTeams.edges.map((item) => {
                    return (
                        <Col xs={12} key={item.node.strapiId} className={props.itemClass}>
                            <OurPeopleCardImg imgClass={props.imgClass} imgNode={item.node.Image} />
                            <div className={props.textItemClass}>
                                {item.node.Name && <OurPeopleCardName name={item.node.Name} titleClass={props.titleClass} />}
                                {item.node.JobTitle && <OurPeopleCardJobTitle jobTitle={item.node.JobTitle} jobTitleClass={props.jobTitleClass} />}
                                <ul className={props.listClass}>
                                    {item.node.PhoneNumber && <li className="list-inline-item"><OurPeopleCardPhone phone={item.node.PhoneNumber} /></li>}
                                    {item.node.CalendlyLink &&
                                        <li className="list-inline-item d-none d-xl-inline-block">
                                            <CalendlyButtonLink
                                                label={item.node.BookVideoLabel}
                                                url={item.node.CalendlyLink}
                                                btnClass="btn btn-none"
                                            />
                                        </li>
                                    }
                                    {item.node.ViewBioLabel && <li className="list-inline-item"><OurPeopleCardBio bioLabel={item.node.ViewBioLabel} bioLink={`/about/our-people/${item.node.Slug}`} /></li>}
                                </ul>
                            </div>
                        </Col>
                    )
                })}
            </>
          )}
        />
    )
}
export default OurPeopleCard;
