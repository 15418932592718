import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from "gatsby";
import VideoModal from "@components/modal/video-modal-img";
import BackgroundImage from 'gatsby-background-image';

const ImageVideo = (props) => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    desktop: file(relativePath: { eq: "young-woman.jpg" }) {
                        childImageSharp {
                        fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                        }
                    }
                }
            `}
            render={data => {
                // Set ImageData.
                const imageData = data.desktop.childImageSharp.fluid
                return (
                    <>
                        {props.showVideo ? (
                            <div className={`image-box-text-module__image${props.evenItem}`}>
                                <VideoModal
                                    imgUrl={props.imgUrl}
                                    showVideo={props.showVideo}
                                    videoId={props.videoId}
                                    videoChannel={props.videoChannel}
                                />
                            </div>
                        ) : (
                            <BackgroundImage
                                Tag="div"
                                className={`image-box-text-module__image${props.evenItem}`}
                                fluid={props.imgUrl ? props.imgUrl : imageData}
                                // preserveStackingContext={true}
                                critical={true}
                                role="img"
                            />
                        )}
                        {/* <BackgroundImage
                            Tag="div"
                            className={`image-box-text-module__image${props.evenItem}`}
                            fluid={props.imgUrl ? props.imgUrl : imageData}
                            // preserveStackingContext={true}
                            critical={true}
                            role="img"
                        >
                        </BackgroundImage> */}
                    </>
                )
            }}
        />
    );
}

// Check all the prop types
ImageVideo.propTypes = {
    evenItem: PropTypes.string
};

export default ImageVideo;