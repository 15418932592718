import React, { useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import  "./assets/_index.scss";
import JobHit from "@components/jobs/jobHit"

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const LatestJobsComponent = (props) => {
    let contAnim = useRef(null);
    let titleAnim = useRef(null);
    let boxAnim = useRef([]);
    boxAnim.current = [];
    let linksAnim = useRef(null);

    const addToRefs = el => {
        if (el && !boxAnim.current.includes(el)) {
            boxAnim.current.push(el);
        }
    };

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            },
            scrollTrigger: {
                trigger: contAnim,
                start: 'top 80%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        });
        tl.fromTo(titleAnim, {
            opacity: 0,
            y: 100,
            duration: 0.8
        },{
            opacity: 1,
            y: 0
        }).fromTo(boxAnim.current, {
            opacity: 0,
            y: 70
        },{
            opacity: 1,
            y: 0,
            stagger: 0.3
        }).from(linksAnim, {
            opacity: 0,
            y: 70
        })
    }, [])
    return(
        <section className={`section section-latest-jobs${props.sectionClass}`} ref={el => contAnim = el}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <h2 className="section-title text-center h5 mb-21 mb-md-35 mb-xl-48" ref={el => titleAnim = el}>{props.Title}</h2>
                        <div className="latest-jobs-list">
                            {props.data.allStrapiJobs.edges.map((hit) => 
                                <JobHit
                                    key={hit.node.jobid}
                                    slug={hit.node.slug}
                                    jobid={hit.node.jobid}
                                    title={hit.node.title}
                                    salary={hit.node.salary}
                                    ote={hit.node.ote}
                                    currency={hit.node.currency}
                                    address={hit.node.address}
                                    websitecountry={hit.node.websitecountry}
                                    innerRef={addToRefs}
                                />
                            )}
                            <ul className="list-inline latest-jobs-list__more mt-21 mb-0 mt-md-40 mt-xl-46" ref={el => linksAnim = el}>
                                <li><a href="/job-search/" className="btn-link-tertiary">View More Jobs</a></li>
                                <li><a href="/job-search/" className="btn-link-tertiary">Search for a Job</a></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

const LatestJobs = (props) => {
    const data = useStaticQuery(graphql`
        query LatestJobQuery {
            allStrapiJobs(filter: {status: {eq: true}}, sort: {fields: posted_data, order: DESC}, limit: 5) {
                edges {
                    node {
                        address {
                            city
                            countryCode
                        }
                        currency
                        jobid
                        ote
                        salary
                        slug
                        status
                        title
                        websitecountry
                    }
                }
            }
        }
    `)
    return(
        <LatestJobsComponent data={data} {...props} />
    )
}

// Check all the prop types
LatestJobs.propTypes = {
    sectionClass: PropTypes.string,
    Title: PropTypes.string,
    latestJobs: PropTypes.array
};

// Specifies the default values for props:
LatestJobs.defaultProps = {
    sectionClass: ' section-grey-bg pt-33 pb-44 pt-md-52 pb-md-60 pt-xl-132 pb-xl-140',
    Title: '',
    latestJobs: [
        {
            Name: 'Business Development Manager',
            Location: "Greenwich, London",
            Salary: "£80,000+",
            Link: "/"
        },
        {
            Name: 'Tenancy Manager',
            Location: "Stoke-On-Trent, Staffordshire ",
            Salary: "£80,000+",
            Link: "/"
        },
        {
            Name: 'Lettings Client Accounts Manager',
            Location: "CV10, Nuneaton",
            Salary: "£80,000+",
            Link: "/"
        },
        {
            Name: 'Property Manager',
            Location: "Dudley, West Midlands",
            Salary: "£80,000+",
            Link: "/"
        },
        {
            Name: 'Graduate Office Coordinator',
            Location: "Birmingham, West Midlands ",
            Salary: "£80,000+",
            Link: "/"
        },
        {
            Name: 'Business Development Manager',
            Location: "Greenwich, London",
            Salary: "£80,000+",
            Link: "/"
        }
    ]
};

export default LatestJobs;