import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import SingleReview from "./_singleReview"

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const GoogleReviews = (props) => {
    let contAnim = useRef(null);
    let titleAnim = useRef(null);
    let boxAnim = useRef([]);
    boxAnim.current = [];

    const addToRefs = el => {
        if (el && !boxAnim.current.includes(el)) {
            boxAnim.current.push(el);
        }
    };

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            },
            scrollTrigger: {
                trigger: contAnim,
                start: 'top 80%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        });
        tl.fromTo(titleAnim, {
            opacity: 0,
            y: 100,
            duration: 0.8
        },{
            opacity: 1,
            y: 0
        }).fromTo(boxAnim.current, {
            opacity: 0,
            y: 70
        },{
            opacity: 1,
            y: 0,
            stagger: 0.3
        })
    }, [])

    const reviews = useStaticQuery(graphql`
        query {
            allStrapiGoogleReviewReviews(sort: {fields: createTime, order: DESC}, filter: {starRating: {in: ["FOUR","FIVE"]}}) {
                edges {
                    node {
                        name
                        comment
                        id
                        reviewer {
                        displayName
                        profilePhotoUrl
                        }
                        starRating
                    }
                }
            }
        }
    `)

    return (
        <section id="scrollToReviews" className={`section section-google-reviews${props.sectionClass}`} ref={el => contAnim = el}>
            <Container>
                <Row>
                    {props.Title &&
                        <Col xs={12}><h2 className={props.titleClass} ref={el => titleAnim = el}>{props.Title}</h2></Col>
                    }
                </Row>
                <Row className={props.moduleClass}>
                    {reviews.allStrapiGoogleReviewReviews.edges.map((item) => (
                        <SingleReview
                            key={item.node.id}
                            boxClass={props.boxClass}
                            starRating={item.node.starRating}
                            comment={item.node.comment}
                            reviewer={item.node.reviewer.displayName}
                            innerRef={addToRefs}
                        />
                    ))}
                </Row>
            </Container>
        </section>
    );
}

// Check all the prop types
GoogleReviews.propTypes = {
    sectionClass: PropTypes.string,
    titleClass: PropTypes.string,
    moduleData: PropTypes.array
};

// Specifies the default values for props:
GoogleReviews.defaultProps = {
    sectionClass: ' section-grey-bg pt-40 pb-20 pt-md-60 pb-md-40 pt-xl-140 pb-xl-100',
    moduleClass: 'd-flex flex-column flex-lg-row',
    boxClass: ' pb-20 pb-xl-40',
    titleClass: 'section-title text-center h5 mb-21 mb-md-35 mb-xl-48',
    btnClass: 'btn btn-outline-secondary mt-21 mt-md-31 mt-xl-38',
    moduleData: [
        {
            id: 1,
            starRating: "TWO",
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis. Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            reviewer: 'Chris Rowe / Foxtons'
        },
        {
            id: 2,
            starRating: "FIVE",
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            reviewer: 'Andrew Smith / Savills'
        },
        {
            id: 1,
            starRating: "TWO",
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            reviewer: 'Chris Rowe / Foxtons'
        },
        {
            id: 2,
            starRating: "FIVE",
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            reviewer: 'Andrew Smith / Savills'
        },
        {
            id: 1,
            starRating: "TWO",
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            reviewer: 'Chris Rowe / Foxtons'
        },
        {
            id: 2,
            starRating: "FIVE",
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            reviewer: 'Andrew Smith / Savills'
        }
    ]
};

export default GoogleReviews