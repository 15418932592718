import React from 'react';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

const AccordionToggleHeader = ({ children, eventKey, handleClick }) => {
    const toggleOnClick = useAccordionToggle(eventKey, () => {
        handleClick();
    });

    return (
        <div className="card-header"
            onClick={toggleOnClick}
            onKeyDown={toggleOnClick}> 
        <span
            role='button'
            tabIndex='0'
            className={`toggleBtn`}
        >
            {children}
        </span>
        </div>
    );
}
export default AccordionToggleHeader;