import React from 'react';
import { Link } from "gatsby"

import  "./assets/_index.scss";

import BlogBoxImg from "@components/blog-box/img";

class BlogBox extends React.Component {
    truncate = (str) => {
        const stripedHtml = str.replace(/<[^>]+>/g, ''); // Remove HTML tags from code of any
        return stripedHtml.length > 10 ? stripedHtml.substring(0, 95) + "..." : stripedHtml;
    }
    render() {
        return (
            <div className={`blog-box${this.props.boxClass}`} ref={this.props.innerRef}>
                <BlogBoxImg
                    slug={this.props.slug}
                    imgClass={this.props.imgClass}
                    imgUrl={this.props.imgUrl}
                    categorylabelName={this.props.categorylabelName}
                    categoryLabelColour={this.props.categoryLabelColour}
                    categoryLabelFontColour={this.props.categoryLabelFontColour}
                />
                <div className={this.props.blogBoxTextClass}>
                    <Link to={this.props.slug}><h4 className={this.props.titleClass}>{this.props.Title} {this.props.Sub_Title}</h4></Link>
                    {this.props.Text && <div className={this.props.textClass}><p>{this.truncate(this.props.Text)} <Link to={this.props.slug}><span>More...</span></Link></p></div>}
                </div>
            </div>
        );
    }
}

// Specifies the default values for props:
BlogBox.defaultProps = {
    boxClass: ' pb-22 pb-md-52 pb-lg-55 d-flex flex-column align-items-start',
    imgClass: 'blog-box__img mb-32 mb-md-43',
    categorylabelName: '',
    categoryLabelColour: '#cccccc',
    categoryLabelFontColour: '#dddddd',
    blogBoxTextClass: 'blog-box__text mx-30',
    titleClass: 'blog-box__title font-weight-bold mb-0 mb-md-12',
    textClass: 'd-none d-md-block',
    Text: null
}

export default React.forwardRef((props, ref) => <BlogBox 
  innerRef={ref} {...props}
/>);