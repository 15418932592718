import React, { useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import  "./assets/_index.scss";

import ImageVideo from "./ImageVideo";
import TextBox from "./TextBox";

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

function evenItem (number, reverse = false) {
    if (reverse) {
        return ((number % 2 === 0) ? ' order-1' : ' order-0');
    } else {
        return ((number % 2 === 0) ? ' order-0' : ' order-1');
    }
}

const ImageBoxText = (props) => {
    let contAnim = useRef([]);
    contAnim.current = [];

    const addToRefs = el => {
        if (el && !contAnim.current.includes(el)) {
            contAnim.current.push(el);
        }
    };

    useEffect(() => {
        contAnim.current.forEach((el, index) => {
            const tl = gsap.timeline({
                defaults: {
                    duration: animSetting.duration,
                    ease: animSetting.ease,
                },
                scrollTrigger: {
                    trigger: el,
                    start: 'top 70%',
                    end: 'bottom bottom',
                    toggleActions: 'play none none reverse',
                    ease: animSetting.ease
                }
            });
            tl.addLabel('start')
            .to(el, {
                duration: 0,
                css: {visibility: 'visible'}
            }, 'start')
            .from(el.children[1], { // .section-image-box-text__text
                opacity: 0,
                x: -70,
                duration: 1.5
            }, 'start+=0.5')
            .from(el.children[0], { // .image-box-text-module__image
                opacity: 0,
                duration: 1.5,
                scale: 0.7,
                y: 100,
            }, 'start+=0.1')
        })
        
    }, [])
    
    // console.log ("asdasd", props.titleClass)
    
    return (
        <section className={`section section-image-box-text ${props.sectionClass}`}>
            <Container>
                <Row>
                    <Col xs={12}>
                        {props.moduleData.map((item, index) => (
                            <div key={item.id} className={`image-box ${props.moduleClass}`} ref={addToRefs}>
                                <ImageVideo
                                    evenItem={evenItem(index, true)}
                                    imgUrl={item.Image ? item.Image.childImageSharp.fluid : null}
                                    showVideo={item.Video ? item.Video.showVideo : null}
                                    videoId={item.Video ? item.Video.videoId : null}
                                    videoChannel={item.Video ? item.Video.videoChannel : null}
                                />
                                <TextBox
                                    boxClass={`${props.boxClass}${evenItem(index)}`}
                                    titleClass={props.titleClass}
                                    btnClass={props.btnClass}
                                    item={item}
                                />
                            </div>
                        ))}
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

// Check all the prop types
ImageBoxText.propTypes = {
    sectionClass: PropTypes.string,
    moduleData: PropTypes.array
};

// Specifies the default values for props:
ImageBoxText.defaultProps = {
    sectionClass: ' section-white pt-40 pt-md-60 pt-xl-140',
    moduleClass: 'pb-40 pb-md-60 pb-xl-140 d-xl-flex w-xl-75 align-items-xl-center',
    boxClass: ' pt-33 pb-40 px-40 pt-md-52 pb-md-60 px-md-60 pt-xl-92 pb-xl-100 px-xl-80',
    titleClass: 'section-title h5 mb-13 mb-md-22 mb-xl-20',
    btnClass: 'btn btn-outline-secondary mt-21 mt-md-31 mt-xl-38',
    moduleData: [
        {
            id: 1,
            imageUrl: 'young-woman.jpg',
            videoUrl: '',
            Title: 'Help & Advice',
            Text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            Buttons: [
                {
                    id: '123213',
                    Label: 'Start Referring',
                    externalLink: null,
                    page: null,
                    targetLink: null
                }
            ]
        },
        {
            id: 2,
            imageUrl: 'young-woman.jpg',
            videoUrl: '',
            Title: 'Help & Advice',
            Text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce sagittis nis.',
            Buttons: [
                {
                    id: 'asdddfs',
                    Label: 'Explore Help & Advice',
                    externalLink: null,
                    page: null,
                    targetLink: null
                }
            ]
        }
    ]
};

export default ImageBoxText