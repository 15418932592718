import React, { useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import Pills from './Pills';
import TabPane from './TabPane';

import  "./assets/_index.scss";

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const SubscriptionTable = (props) => {
    let contAnim = useRef(null);
    let titleAnim = useRef(null);
    let tabsAnim = useRef(null);
    let contentAnim = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            },
            scrollTrigger: {
                trigger: contAnim,
                start: 'top 80%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        });
        tl.to(contAnim, {
            duration: 0,
            css: {visibility: 'visible'}
        })
        .from([titleAnim, tabsAnim, contentAnim], {
            opacity: 0,
            y: 70,
            duration: 1.5,
            stagger: 0.6
        })
    }, [])
    return (
        <section id="subscription-table" className={`section section-subscription-table${props.sectionClass}`} ref={el => contAnim = el}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <h2 className={props.titleClass} ref={el => titleAnim = el}>{props.Title}</h2>

                        {
                            props.pillsData && props.pillsData.length > 0 && (
                            <Tab.Container id="subscription-table" defaultActiveKey={`pill-${props.pillsData[0].id}`}>
                            <Row>
                                <Col sm={12}>
                                    <Nav justify variant="pills" className={`flex-row${props.pillsClass}`} ref={el => tabsAnim = el}>
                                        {props.pillsData.map((item, index) => (
                                            <Pills
                                                key={item.id}
                                                eventKey={`pill-${item.id}`}
                                                pillTitle={item.pillTitle}
                                                pillsClass={props.pillsClass}
                                                pillActive={item.active}
                                            />
                                        ))}
                                    </Nav>
                                </Col>
                                <Col sm={12}>
                                    <Tab.Content className={props.tabContainerClass} ref={el => contentAnim = el}>
                                        {props.pillsData.map((item, index) => (
                                            <TabPane
                                                key={item.id}
                                                eventKey={`pill-${item.id}`}
                                                tabContentClass={props.tabContentClass}
                                                tabItemClass={props.tabItemClass}
                                                tabData={item.tabData}
                                            />
                                        ))}
                                    </Tab.Content>
                                </Col>
                            </Row>
                            </Tab.Container>                    
                        )
                    }
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

// Check all the prop types
SubscriptionTable.propTypes = {
    sectionClass: PropTypes.string,
    titleClass: PropTypes.string,
    pillsClass: PropTypes.string,
    tabContainerClass: PropTypes.string,
    tabContentClass: PropTypes.string,
    tabItemClass: PropTypes.string,
    Title: PropTypes.string,
    pillsData: PropTypes.array
};

// Specifies the default values for props:
SubscriptionTable.defaultProps = {
    sectionClass: '',
    titleClass:'section-title text-center h5 mb-21 mb-md-35 mb-xl-48',
    Title: 'Do you need to hire multiple people? We offer affordable, flexible subscription plans.',
    pillsClass: ' cruxcareers-tab-pills mb-40 mb-xl-60',
    tabContainerClass: 'cruxcareers-tab-content',
    tabContentClass: ' col-12 col-xl-4',
    tabItemClass: ' subscription-table__item text-center mb-20 mb-xl-0 px-40 py-34 px-md-47 px-xl-70 py-xl-54',
    pillsData: [
        {
            id: 1,
            pillTitle: 'Estate Agent',
            tabData: [
                {
                    id: 1,
                    title: 'Standard',
                    text: '<p>12.5% fee* – payable on day one when the candidate starts.</p>',
                    disclaimer: '*Based on a fixed, basic salary (including car allowance – excludes commission)',
                    btnLabel: 'Get Started',
                    btnClass: 'btn btn-primary',
                    btnLink: '/employers/register/',
                    btnLinkType: 'internal'
                },
                {
                    id: 2,
                    title: 'Subscription',
                    text: '<p><strong>£80 per month</strong> with a fixed fee of <strong>£799</strong> + VAT upon a candidate being placed.</p><p><strong>The subscription is for a minimum of 12 months.</strong></p><p><strong>Have your pick of the top candidates on the market</strong></p><p><strong>Monthly training session for your new candidates</strong></p>',
                    disclaimer: '',
                    btnLabel: 'Get Started',
                    btnClass: 'btn btn-primary',
                    btnLink: '/employers/register/',
                    btnLinkType: 'internal'
                },
                {
                    id: 3,
                    title: 'Pay as you go',
                    text: '<p>15% fee* - payable over a 6 month period after the candidate starts.</p>',
                    disclaimer: '*Based on a fixed, basic salary (including car allowance – excludes commission)',
                    btnLabel: 'Get Started',
                    btnClass: 'btn btn-primary',
                    btnLink: '/employers/register/',
                    btnLinkType: 'internal'
                }
            ]
        },
        {
            id: 2,
            pillTitle: 'PropTech',
            tabData: [
                {
                    id: 1,
                    title: 'Standard',
                    text: '<p>12.5% fee* – payable on day one when the candidate starts.</p>',
                    disclaimer: '*Based on a fixed, basic salary (including car allowance – excludes commission)',
                    btnLabel: 'Get Started',
                    btnClass: 'btn btn-primary',
                    btnLink: '/employers/register/',
                    btnLinkType: 'internal'
                },
                {
                    id: 2,
                    title: 'Subscription',
                    text: '<p><strong>£80 per month</strong> with a fixed fee of <strong>£799</strong> + VAT upon a candidate being placed.</p><p><strong>The subscription is for a minimum of 12 months.</strong></p><p><strong>Have your pick of the top candidates on the market</strong></p><p><strong>Monthly training session for your new candidates</strong></p>',
                    disclaimer: '',
                    btnLabel: 'Get Started',
                    btnClass: 'btn btn-primary',
                    btnLink: '/employers/register/',
                    btnLinkType: 'internal'
                },
                {
                    id: 3,
                    title: 'Pay as you go',
                    text: '<p>15% fee* - payable over a 6 month period after the candidate starts.</p>',
                    disclaimer: '*Based on a fixed, basic salary (including car allowance – excludes commission)',
                    btnLabel: 'Get Started',
                    btnClass: 'btn btn-primary',
                    btnLink: '/employers/register/',
                    btnLinkType: 'internal'
                }
            ]
        }
    ]
};

export default SubscriptionTable;